module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-glamor/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"scope":"body","mode":"async","enableListener":true,"web":[{"name":["freight-text-pro","proxima-nova"],"file":"https://use.typekit.net/oxm1upu.css"}],"preconnect":["https://use.typekit.net"],"interval":300,"timeout":30000},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
