// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-healthredesigned-subscribe-index-js": () => import("./../../../src/pages/healthredesigned/subscribe/index.js" /* webpackChunkName: "component---src-pages-healthredesigned-subscribe-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-templates-page-text-js": () => import("./../../../src/templates/page-text.js" /* webpackChunkName: "component---src-templates-page-text-js" */)
}

